import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { isPC } from "@/utils/device";
import Storage from "@/utils/storage";
import en from "element-plus/es/locale/lang/en";
export default {
  setup() {
    const $store = useStore();
    const locale = computed(() => $store.getters.locale === "en" ? en : null);
    onMounted(() => {
      let localStyle = Storage.get("themeStyle") || "light";
      Storage.set("themeStyle", localStyle);
      $store.dispatch("user/changeTheme", localStyle);
      let localLanguage = $store.getters.locale || "en";
      if (isPC()) {
        document.getElementsByTagName("body")[0].className = localStyle + "-mode pc-mode " + localLanguage;
      } else {
        document.getElementsByTagName("body")[0].className = localStyle + "-mode mobile-mode " + localLanguage;
      }
    });
    return {
      locale
    };
  }
};