const state = {
  step: 0,
  data: {
    agree: {
      isAgree: "0",
      companyType: "1"
    },
    account: {},
    company: {},
    bank: {}
  }
};

const mutations = {
  NEXT_STEP(state, obj){
    state.step = state.step + 1;
  },
  PREV_STEP(state, obj){
    if (state.step > 0) {
      state.step = state.step - 1;
    }
  },
  RESET_STEP(state, obj){
    state.step = 0;
  },
  SET_AGREE(state, obj) {
    state.data = {...state.data, agree: obj};
  },
  SET_ACCOUNT(state, obj) {
    state.data = {...state.data, account: obj};
  },
  SET_COMPANY(state, obj) {
    state.data = {...state.data, company: obj};
  },
  SET_BANK(state, obj) {
    state.data = {...state.data, bank: obj};
  },
  RESET_DATA(state, obj) {
    state.data = {
      agree: {
        isAgree: "0",
        companyType: "1"
      },
      account: {},
      company: {},
      bank: {}
    };
  }
};

const actions = {
  next({ commit }, data) {
    commit('NEXT_STEP', data)
  },
  prev({ commit }, data) {
    commit('PREV_STEP', data)
  },
  resetStep({ commit }, data) {
    commit('RESET_STEP', data)
  },
  setAgree({ commit }, data) {
    commit('SET_AGREE', data)
  },
  setAccount({ commit }, data) {
    commit('SET_ACCOUNT', data)
  },
  setCompany({ commit }, data) {
    commit('SET_COMPANY', data)
  },
  setBank({ commit }, data) {
    commit('SET_BANK', data)
  },
  resetData({ commit }, data) {
    commit('RESET_DATA', data)
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
