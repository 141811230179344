import Storage from "@/utils/storage";
import { enterStore } from "@/api/user";

const state = {
  storeInfo: {},
};

const mutations = {
  SET_STORE_INFO: (state, storeInfo) => {
    state.storeInfo = storeInfo;
  },
};

const actions = {
  setStoreInfo({ commit }, data) {
    return new Promise((resolve, reject) => {
      enterStore({param: data}).then(res => {
        if (res.success) {
          commit('SET_STORE_INFO', res.data)
          resolve(res);
        } else {
          reject(res.message)
        }
      }).catch(error => {
        reject(error)
      })
    })
  },
  clearStoreInfo({ commit }, data) {
    commit('SET_STORE_INFO', {})
    Storage.remove("storeInfo")
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
