import { createStore } from "vuex";
import getters from "./getters";
import state from "./state";
import user from "./modules/user";
import store from "./modules/store";
import search from "./modules/search";
import nav from "./modules/nav";
import regist from "./modules/regist";
import menu from "./modules/menu";
import step from "./modules/step";
import cart from "./modules/cart";
import save from "./modules/save";
import view from "./modules/view";
import createPersistedState from "vuex-persistedstate";
export default createStore({
  modules: {
    user,
    store,
    search,
    nav,
    regist,
    menu,
    step,
    cart,
    save,
    view,
  },
  getters,
  state,
  plugins: [createPersistedState()],
});
