import { saveMemViewHistoryList } from "@/api/product";
import { getToken } from "@/utils/auth";
import moment from "moment";

const state = {
  goodsList: [],
};

const mutations = {
  SET_GOODS_LIST: (state, locale) => {
    state.goodsList = locale;
  },
};

const actions = {
  // 保存浏览记录到后台
  save({ commit, state }, params) {
    return new Promise((resolve) => {
      if (state.goodsList.length > 0) {
        saveMemViewHistoryList({goodsViewed: state.goodsList}).then(res => {
          if (res.success) {
            commit("SET_GOODS_LIST", []);
            resolve();
          }
        })
      }
    });
  },

  // 添加浏览记录
  add({ commit, state }, params) {
    return new Promise((resolve) => {
      if (getToken('ISTUDY_TOKEN')) {
        saveMemViewHistoryList({singleGoodsId: params}).then(res => {
          if (res.success) {
            resolve();
          }
        })
      } else {
        let goodsList = state.goodsList.filter(item => item.goodsId != params);
        goodsList.push({goodsId: params, viewTime: moment()});
        commit("SET_GOODS_LIST", goodsList);
        resolve();
      }
    });
  },

  // 清除浏览记录
  clear({ commit, state }, params) {
    return new Promise((resolve) => {
      commit("SET_GOODS_LIST", []);
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
