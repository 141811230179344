const mobileRouter = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/auth",
    name: "auth",
    component: () => import("@/views/mobile/auth/Index.vue"),
    redirect: "/login",
    children: [{
        path: "/login",
        name: "login",
        component: () => import("@/views/mobile/auth/Login.vue"),
      },
      {
        path: '/404',
        name: "404",
        component: () => import('@/views/mobile/error/404.vue'),
      },
      {
        path: '/401',
        name: "401",
        component: () => import('@/views/mobile/error/401.vue'),
      },
      {
        path: "/forgetpassword",
        name: "forgetPassword",
        component: () => import("@/views/mobile/auth/ForgotPassword.vue"),
      },
      {
        path: "/regist",
        name: "regist",
        component: () => import("@/views/mobile/auth/Regist.vue"),
      }
    ]
  },
  {
    path: "/index",
    name: "index",
    component: () => import("@/views/mobile/homepage/Index.vue"),
    redirect: "/home",
    children: [{
        path: "/home",
        name: "homepage.menu.home",
        meta: { auth: false, breadNumber: 0, title: 'homepage.menu.home' },
        component: () => import("@/views/mobile/homepage/home/Index.vue"),
      },
      {
        path: "/search",
        name: "homepage.menu.course_list",
        meta: { auth: false, breadNumber: 1 },
        component: () => import("@/views/mobile/homepage/search/Index.vue"),
      },
      {
        path: "/product/:id",
        name: "homepage.menu.course_details",
        meta: { auth: false, breadNumber: 2 },
        component: () => import("@/views/mobile/homepage/product/Index.vue"),
      },
      {
        path: "/checkout",
        name: "homepage.menu.checkout",
        meta: { auth: false, breadNumber: 1 },
        component: () => import("@/views/mobile/homepage/checkout/Index.vue"),
      },
      {
        path: "/order/:id",
        name: "homepage.menu.order",
        meta: { auth: false, breadNumber: 1 },
        component: () => import("@/views/mobile/homepage/order/Index.vue"),
      },
      {
        path: "/institution",
        name: "homepage.menu.institution_details",
        meta: { auth: false, breadNumber: 1 },
        component: () => import("@/views/mobile/homepage/institution/Index.vue"),
      },
      {
        path: "/instructor",
        name: "homepage.menu.instructor_details",
        meta: { auth: false, breadNumber: 2 },
        component: () => import("@/views/mobile/homepage/instructor/Index.vue"),
      },
      {
        path: "/aboutus",
        name: "homepage.menu.about_us",
        meta: { auth: false, breadNumber: 1 },
        component: () => import("@/views/mobile/homepage/AboutUs.vue"),
      },
      {
        path: "/term",
        name: "homepage.menu.term_condition",
        meta: { auth: false, breadNumber: 1 },
        component: () => import("@/views/mobile/homepage/TermCondition.vue"),
      },
      {
        path: "/privacy",
        name: "homepage.menu.privacy_policy",
        meta: { auth: false, breadNumber: 1 },
        component: () => import("@/views/mobile/homepage/PrivacyPolicy.vue"),
      },
      {
        path: "/contactus",
        name: "homepage.menu.contact_us",
        meta: { auth: false, breadNumber: 1 },
        component: () => import("@/views/mobile/homepage/ContactUs.vue"),
      },
      {
        path: "/termcondition",
        name: "homepage.menu.term_condition",
        meta: { auth: false, breadNumber: 1 },
        component: () => import("@/views/mobile/homepage/TermCondition.vue"),
      },
    ]
  },
  {
    path: "/student/index",
    name: "studentIndex",
    component: () => import("@/views/mobile/student/Index.vue"),
    redirect: "/student/live",
    children: [
      {
        path: "/student/virtual",
        name: "student.menu.virtual_courses",
        meta: { auth: true, breadNumber: 1, breadFirst: 'student.menu.my_learning' },
        component: () => import("@/views/mobile/student/virtual/Index.vue")
      },
      {
        path: "/student/virtual/details/:id",
        name: "student.menu.virtual_details",
        meta: { auth: true, breadNumber: 2 },
        component: () => import("@/views/mobile/student/virtual/Details.vue"),
      },
      {
        path: "/student/live",
        name: "student.menu.live_courses",
        meta: { auth: true, breadNumber: 1, breadFirst: 'student.menu.learning' },
        component: () => import("@/views/mobile/student/live/Index.vue")
      },
      {
        path: "/student/live/details/:id",
        name: "student.menu.live_details",
        meta: { auth: true, breadNumber: 2 },
        component: () => import("@/views/mobile/student/live/Details.vue"),
      },
      {
        path: "/student/record",
        name: "student.menu.record_courses",
        meta: { auth: true, breadNumber: 1, breadFirst: 'student.menu.learning' },
        component: () => import("@/views/mobile/student/record/Index.vue"),
      },
      {
        path: "/student/record/details/:id",
        name: "student.menu.record_details",
        meta: { auth: true, breadNumber: 2 },
        component: () => import("@/views/mobile/student/record/Details.vue"),
      },
      {
        path: "/student/spaceSubscription",
        name: "student.menu.space_subscription",
        meta: { auth: true, breadNumber: 1, breadFirst: 'student.menu.plan' },
        component: () => import("@/views/mobile/student/space/subscription/Index.vue"),
      },
      {
        path: "/student/spaceUsages",
        name: "student.menu.usage_list",
        meta: { auth: true, breadNumber: 1, breadFirst: 'student.menu.plan' },
        component: () => import("@/views/mobile/student/space/usages/Index.vue"),
      },
      {
        path: "/student/personal",
        name: "student.menu.personal_info",
        meta: { auth: true, breadNumber: 1, breadFirst: 'student.menu.account' },
        component: () => import("@/views/mobile/student/account/personal/Index.vue"),
      },
      {
        path: "/student/security",
        name: "student.menu.sign_in_security",
        meta: { auth: true, breadNumber: 1, breadFirst: 'student.menu.account' },
        component: () => import("@/views/mobile/student/account/security/Index.vue"),
      },
      {
        path: "/student/security/changePassword",
        name: "student.menu.change_password",
        meta: { auth: true, breadNumber: 2 },
        component: () => import("@/views/mobile/student/account/security/ChangePassword.vue"),
      },
      {
        path: "/student/security/changeEmail",
        name: "student.menu.change_email",
        meta: { auth: true, breadNumber: 2 },
        component: () => import("@/views/mobile/student/account/security/ChangeEmail.vue"),
      },
      {
        path: "/student/security/changePhone",
        name: "student.menu.change_phone",
        meta: { auth: true, breadNumber: 2 },
        component: () => import("@/views/mobile/student/account/security/ChangePhone.vue"),
      },
      {
        path: "/student/identity",
        name: "student.menu.identity_verification",
        meta: { auth: true, breadNumber: 1, breadFirst: 'student.menu.account' },
        component: () => import("@/views/mobile/student/account/identity/Index.vue"),
      },
      {
        path: "/student/viewHistory",
        name: "student.menu.view_history",
        meta: { auth: true, breadNumber: 1, breadFirst: 'student.menu.account' },
        component: () => import("@/views/mobile/student/account/history/Index.vue"),
      },
      {
        path: "/student/wishlist",
        name: "student.menu.wishlist",
        meta: { auth: true, breadNumber: 1, breadFirst: 'student.menu.account' },
        component: () => import("@/views/mobile/student/account/wishlist/Index.vue"),
      },
      {
        path: "/student/order",
        name: "student.menu.order_list",
        meta: { auth: true, breadNumber: 1, breadFirst: 'student.menu.others' },
        component: () => import("@/views/mobile/student/order/Index.vue"),
      },
      {
        path: "/student/order/details/:id",
        name: "student.menu.order_details",
        meta: { auth: true, breadNumber: 2 },
        component: () => import("@/views/mobile/student/order/Details.vue"),
      },
      {
        path: "/student/order/payment/:id",
        name: "student.menu.order_payment",
        meta: { auth: true, breadNumber: 2 },
        component: () => import("@/views/mobile/student/order/PayConfirm.vue"),
      },
      {
        path: "/student/help",
        name: "student.menu.help_center",
        meta: { auth: true, breadNumber: 1, breadFirst: 'student.menu.others' },
        component: () => import("@/views/mobile/student/center/Index.vue"),
      },
      {
        path: "/student/refund",
        name: "student.menu.refund_order",
        meta: { auth: true, breadNumber: 2, breadFirst: 'student.menu.others'},
        component: () => import("@/views/mobile/student/center/refund/Index.vue"),
      },
      {
        path: "/student/refund/details/:id",
        name: "student.menu.refund_details",
        meta: { auth: true, breadNumber: 2, breadFirst: 'student.menu.others'},
        component: () => import("@/views/mobile/student/center/refund/Details.vue"),
      },
      {
        path: "/student/review",
        name: "student.menu.review_feedback",
        meta: { auth: true, breadNumber: 2, breadFirst: 'student.menu.others'},
        component: () => import("@/views/mobile/student/center/review/Index.vue"),
      },
      {
        path: "/student/question",
        name: "student.menu.question_reply",
        component: () => import("@/views/mobile/student/center/question/Index.vue"),
        meta: { auth: true, breadNumber: 2, breadFirst: 'student.menu.others'},
      }
    ]
  },
];
export default mobileRouter;
