import { createRouter, createWebHistory } from "vue-router";
import pcRoutner from "./pc";
import mobileRouter from "./mobile";
import { isPC } from "@/utils/device";
import Storage from "@/utils/storage";
import { getPageTitle } from '@/utils/page'
import VueI18n from "@/language";

const isPc = isPC();
Storage.set("isPc", isPc);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: isPc ? pcRoutner : mobileRouter,
  scrollBehavior(to, from, savedPosition) {
    // 解决路由跳转后 会滚动到底部
    if (savedPosition) {
      return savedPosition;
    } else {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
  },
});

router.beforeEach((to, from, next) => {
  // console.log('router', to);
  setMeta(to.meta);
  next();
});

const setMeta = (meta) => {
  if (meta.title) {
    document.title = getPageTitle(VueI18n.global.t(meta.title));
  }
};

export default router;
