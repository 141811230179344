import axios from "axios";
import store from "@/store";
import { getToken } from "@/utils/auth";
import CONFIG from "@/config/baseUrl";
import { ElMessage, ElMessageBox } from "element-plus";
import { showLoading, hideLoading } from "@/utils/loading";

// create an axios instance
const service = axios.create({
  baseURL: CONFIG.baseUrl, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 3 * 60 * 1000
});

const pending = [];
const CancelToken = axios.CancelToken;

const removePending = (ever) => {
  for (const p in pending) {
    const data = JSON.stringify(ever.data);
    if (pending[p].u === ever.url + "&" + data) { // 当前请求在数组中存在时执行函数体
      pending[p].f(); // 执行取消操作
      pending.splice(p, 1); // 把这条记录从数组中移除
    }
  }
};

// request interceptor
service.interceptors.request.use(
  config => {
    // 请求拦截进来调用显示loading效果
    showLoading();

    // do something before request is sent
    removePending(config); // 在请求前执行一下取消操作
    config.cancelToken = new CancelToken((c) => {
      // 标识请求地址&请求方式拼接的字符
      pending.push({ u: config.url + "&" + JSON.stringify(config.data), f: c });
    });
    if (store.getters.token) {
      // ISTUDY_TOKEN
      config.headers["X-Auth-Token"] = getToken("ISTUDY_TOKEN");
      config.headers["X-Auth-Type"] = "app";
      config.headers["Content-Type"] = "application/json";
    }
    config.headers["X-Auth-Lang"] = store.getters.locale;
    return config;
  },
  error => {
    // do something with request error
    // console.log(error); // for debug
    return Promise.reject(error);
  }
)

// response interceptor
service.interceptors.response.use(
  response => {

    // 响应拦截进来隐藏loading效果，此处采用延时处理是合并loading请求效果，避免多次请求loading关闭又开启
    setTimeout(() => {
      hideLoading();
    }, 200);

    const res = response.data;
    removePending(response.config); // 执行一下取消操作，把已经完成的请求从pending中移除
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 200) {
      // console.log('res', res)
      ElMessage({
        message: res.message || "Error",
        type: "error",
        duration: 5 * 1000
      });

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 402) {
        // to re-login
        ElMessageBox.confirm("Session Time Out", "Warning", {
          confirmButtonText: "Go to login",
          cancelButtonText: "Cancle",
          type: "warning"
        }).then(() => {
          store.dispatch("user/resetToken").then(() => {
            location.reload();
          });
        });

        return Promise.reject(new Error(res.message || "Error"));
      }
      return res;
    } else {
      return res;
    }
  },
  error => {
    // 响应拦截进来隐藏loading效果，此处采用延时处理是合并loading请求效果，避免多次请求loading关闭又开启
    setTimeout(() => {
      hideLoading();
    }, 200);
    if (error.constructor.name !== 'Cancel') {
      ElMessage({
        message: error.message,
        type: "error",
        duration: 5 * 1000
      });
      return Promise.reject(error);
    }
    return {};
  }
)

export default service;
