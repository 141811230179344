import { memberLogin, instructorLogin, getMemberInfo } from "@/api/user";
import { findSysSettingList } from "@/api/user";
import { getToken, setToken, removeToken } from "@/utils/auth";
import { load } from "@/utils/stripe";
import Storage from "@/utils/storage";

const state = {
  locale: "en",
  theme: "light",
  device: "pc",
  token: getToken("ISTUDY_TOKEN"),
  userInfo: {},
  settings: [],
  stripe: false,
  visitRole: 'visitor',
};

const mutations = {
  SET_LOCAL: (state, locale) => {
    state.locale = locale;
  },
  SET_THEME: (state, theme) => {
    // console.log(theme);
    state.theme = theme;
  },
  SET_DEVICE: (state, device) => {
    // console.log(device);
    state.device = device;
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
    setToken("ISTUDY_TOKEN", token);
  },
  SET_USER_INFO: (state, userInfo) => {
    state.userInfo = userInfo;
  },
  SET_SETTINGS: (state, settings) => {
    state.settings = settings;
  },
  SET_STRIPE: (state, value) => {
    state.stripe = value;
  },
  SET_VISIT_ROLE: (state, visitRole) => {
    state.visitRole = visitRole;
  },
};

const setting_ids = [
  'BANNER_ADVERT',
  'TOP_CATEGORY_1',
  'TOP_CATEGORY_2',
  'TOP_CATEGORY_3',
  'TOP_CATEGORY_4',
  'TOP_CATEGORY_5',
  'TOP_CATEGORY_6',
  'TOP_CATEGORY_7',
  'TOP_CATEGORY_8',
  'STORE_AGREE_YEARS', 
  'STORE_ANNUAL_FEE', 
  'STORE_DEPOSIT', 
  'STUDENT_NUM', 
  'COURSE_NUM', 
  'INSTRUCTOR_NUM', 
];

const actions = {
  // 修改语言
  changeLocal({ commit }, params) {
    return new Promise((resolve) => {
      commit("SET_LOCAL", params);
      resolve();
    });
  },
  // 修改主题
  changeTheme({ commit }, params) {
    return new Promise((resolve) => {
      commit("SET_THEME", params);
      resolve();
    });
  },
  // 改变设备
  changeDevice({ commit }, params) {
    return new Promise((resolve) => {
      commit("SET_DEVICE", params);
      resolve();
    });
  },

  // 登录
  login({ commit }, params) {
    let apiCall = memberLogin;
    if (params.type === "1") {
      apiCall = instructorLogin;
    }
    return new Promise((resolve, reject) => {
      apiCall(params).then(res => {
        if (res.success) {
          const { data } = res
          commit("SET_TOKEN", data.token);
          commit("SET_USER_INFO", data);
          Storage.set("userInfo", data);
          commit("SET_VISIT_ROLE", 'membership');
          resolve(res);
        } else {
          reject(res.message)
        }
      }).catch(error => {
        reject(error)
      })
    })
  },
  
  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit("SET_TOKEN", "")
      commit("SET_USER_INFO", null);
      Storage.remove("userInfo")
      removeToken("ISTUDY_TOKEN")
      commit("SET_VISIT_ROLE", 'visitor');
      resolve()
    })
  },

  // 获取UserInfo
  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getMemberInfo().then(response => {
        const { data } = response
        commit("SET_USER_INFO", data);
        Storage.set("userInfo", data)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit("SET_TOKEN", "")
      commit("SET_USER_INFO", null);
      Storage.remove("userInfo")
      removeToken("ISTUDY_TOKEN")
      resolve()
    })
  },
  
  // set system settings
  setSettings({ commit }) {
    return new Promise((resolve, reject) => {
      findSysSettingList({paging: false, ids: setting_ids}).then(res => {
        if (res.success) {
          const { data } = res;
          commit('SET_SETTINGS', data);
          resolve(res);
        } else {
          reject(res.message)
        }
      }).catch(error => {
        reject(error)
      })
    })
  },

  // 加载stripe
  loadStripe({ commit }) {
    return new Promise((resolve, reject) => {
      load().then(() => {
        commit("SET_STRIPE", true);
        resolve();
      }).catch(error => {
        reject(error)
      })
    })
  },

  // 作为过客
  asVisitor({ commit }) {
    return new Promise((resolve) => {
      commit("SET_VISIT_ROLE", 'visitor');
      resolve();
    });
  },

  // 作为讲师
  asInstructor({ commit }) {
    return new Promise((resolve) => {
      commit("SET_VISIT_ROLE", 'instructor');
      resolve();
    });
  },

  // 作为学生会员
  asMembership({ commit }) {
    return new Promise((resolve) => {
      commit("SET_VISIT_ROLE", 'membership');
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
