const getters = {
  locale: (state) => state.user.locale,
  theme: (state) => state.user.theme,
  device: (state) => state.user.device,
  token: (state) => state.user.token,
  userInfo: (state) => state.user.userInfo,
  visitRole: (state) => state.user.visitRole,
  roleFunction: (state) => {
    return function(path) {
      let info = state.store.storeInfo;
      if (!info || !info.stoSellerVo) {
        return null;
      }

      let arr = info.stoSellerVo.stoRoleFunctionListVos.filter(item => {
        let res = false;
        item.route.split(";").forEach(route => {
          if (path.indexOf(route) === 0) res = true;
        })
        return res;
      });
      if (arr.length > 0) return arr[0].permissionClass;
      else return null;
    }
  },
  stripe: (state) => state.user.stripe,
  settings: (state) => {
    let value = [];
    state.user.settings.forEach(item => {
      if (item.id.match(new RegExp("^TOP_CATEGORY_.*$"))) {
        if (item.strValue) {
          let value = item.strValue.split(",");
          item.categoryName = value[0];
          item.categoryId = value[1];
        }
      }
      value[item.id] = item;
    })
    return value;
  },
  storeInfo: (state) => state.store.storeInfo,
  sellerInfo: (state) => state.store.storeInfo.stoSellerVo,
  tabName: (state) => state.search.tabName,
  searchQuery: (state) => state.search.searchQuery,
  navPathList: (state) => state.nav.navPathList,
  registStep: (state) => state.regist.step,
  registData: (state) => state.regist.data,
  institutionIndex: (state) => state.menu.institutionIndex,
  studentIndex: (state) => state.menu.studentIndex,
  showMobileMenu: (state) => state.menu.showMobileMenu,
  courseStep: (state) => state.step.courseStep,
  cartInfo: (state) => state.cart.cartInfo,
  inCart: (state) => {
    return function(goodsId) {
      let arr= state.cart.cartInfo.filter(item => item.goodsId === goodsId);
      return arr.length > 0;
    }
  },
  goodsSave: (state) => {
    return function(goodsId) {
      let arr = state.save.goodsList.filter(item => item.goodsId === goodsId);
      if (arr.length > 0) return arr[0];
      else return null;
    }    
  },
  storeSave: (state) => {
    return function(storeId) {
      let arr = state.save.storeList.filter(item => item.storeId === storeId);
      if (arr.length > 0) return arr[0];
      else return null;
    }    
  },
  address: (state) => {
    if (state.user.userInfo) {
      return {
        addressLine: state.user.userInfo.addressLine,
        city: state.user.userInfo.city,
        state: state.user.userInfo.state,
        postcode: state.user.userInfo.postcode,
      };
    } else {
      return {
        addressLine: "",
        city: "",
        state: "",
        postcode: "",
      };
    }
  }
};
export default getters;
