export default {
  name: "svgIcon",
  props: {
    name: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ""
    }
  },
  computed: {
    svgClass() {
      //svg 的class
      if (this.className) {
        return `svg-icon ${this.className}`;
      } else {
        return "svg-icon";
      }
    },
    iconName() {
      //svg xlink-href 指向的文件名
      return `#icon-${this.name}`;
    }
  }
};