const pcRouter = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/auth",
    name: "auth",
    component: () => import("@/views/pc/auth/Index.vue"),
    redirect: "/login",
    children: [{
        path: "/login",
        name: "login",
        component: () => import("@/views/pc/auth/Login.vue"),
      },
      {
        path: '/404',
        name: "404",
        component: () => import('@/views/pc/error/404.vue'),
      },
      {
        path: '/401',
        name: "401",
        component: () => import('@/views/pc/error/401.vue'),
      },
      {
        path: "/selectinstruct",
        name: "selectInstruct",
        component: () => import("@/views/pc/auth/SelectInstruct.vue"),
      },
      {
        path: "/forgetpassword",
        name: "forgetPassword",
        component: () => import("@/views/pc/auth/ForgotPassword.vue"),
      },
      {
        path: "/regist",
        name: "regist",
        component: () => import("@/views/pc/auth/Regist.vue"),
      }
    ]
  },
  {
    path: "/index",
    name: "index",
    component: () => import("@/views/pc/homepage/Index.vue"),
    redirect: "/home",
    children: [{
        path: "/home",
        name: "homepage.menu.home",
        meta: { auth: false, breadNumber: 0, title: 'homepage.menu.home'},
        component: () => import("@/views/pc/homepage/home/Index.vue"),
      },
      {
        path: "/invite/:id",
        name: "homepage.menu.invite",
        meta: { auth: false, breadNumber: 0 },
        component: () => import("@/views/pc/homepage/home/Index.vue"),
      },
      {
        path: "/search",
        name: "homepage.menu.course_list",
        meta: { auth: false, breadNumber: 1 },
        component: () => import("@/views/pc/homepage/search/Index.vue"),
      },
      {
        path: "/product/:id",
        name: "homepage.menu.course_details",
        meta: { auth: false, breadNumber: 2 },
        component: () => import("@/views/pc/homepage/product/Index.vue"),
      },
      {
        path: "/checkout",
        name: "homepage.menu.checkout",
        meta: { auth: false, breadNumber: 1 },
        component: () => import("@/views/pc/homepage/checkout/Index.vue"),
      },
      {
        path: "/order/:id",
        name: "homepage.menu.order",
        meta: { auth: false, breadNumber: 1 },
        component: () => import("@/views/pc/homepage/order/Index.vue"),
      },
      {
        path: "/institution",
        name: "homepage.menu.institution_details",
        meta: { auth: false, breadNumber: 1 },
        component: () => import("@/views/pc/homepage/institution/Index.vue"),
      },
      {
        path: "/instructor",
        name: "homepage.menu.instructor_details",
        meta: { auth: false, breadNumber: 2 },
        component: () => import("@/views/pc/homepage/instructor/Index.vue"),
      },
      {
        path: "/aboutus",
        name: "homepage.menu.about_us",
        meta: { auth: false, breadNumber: 1 },
        component: () => import("@/views/pc/homepage/AboutUs.vue"),
      },
      {
        path: "/term",
        name: "homepage.menu.term_condition",
        meta: { auth: false, breadNumber: 1 },
        component: () => import("@/views/pc/homepage/TermCondition.vue"),
      },
      {
        path: "/privacy",
        name: "homepage.menu.privacy_policy",
        meta: { auth: false, breadNumber: 1 },
        component: () => import("@/views/pc/homepage/PrivacyPolicy.vue"),
      },
      {
        path: "/institution_regist",
        name: "homepage.menu.institution_regist",
        meta: { auth: false },
        component: () => import("@/views/pc/institution/regist/Index.vue"),
      }      
    ]
  },
  {
    path: "/institution/regist",
    name: "institution.menu.regist",
    component: () => import("@/views/pc/institution/regist/Index.vue"),
    redirect: "/institution/regist/start",
    children: [{
        path: "/institution/regist/start",
        name: "institution.menu.regist_start",
        meta: { auth: false, breadNumber: 1 },
        component: () => import("@/views/pc/institution/regist/Start.vue"),
      },
      {
        path: "/institution/regist/process",
        name: "institution.menu.regist_process",
        meta: { auth: true, breadNumber: 1 },
        component: () => import("@/views/pc/institution/regist/Process.vue"),
        redirect: "/institution/regist/step1",
        children: [{
            path: "/institution/regist/step1",
            name: "institution.menu.regist_step1",
            meta: { auth: true, breadNumber: 2 },
            component: () => import("@/views/pc/institution/regist/Step1.vue"),
          },
          {
            path: "/institution/regist/step2",
            name: "institution.menu.regist_step2",
            meta: { auth: true, breadNumber: 2 },
            component: () => import("@/views/pc/institution/regist/Step2.vue"),
          },
          {
            path: "/institution/regist/step3",
            name: "institution.menu.regist_step3",
            meta: { auth: true, breadNumber: 2 },
            component: () => import("@/views/pc/institution/regist/Step3.vue"),
          },
          {
            path: "/institution/regist/step4",
            name: "institution.menu.regist_step4",
            meta: { auth: true, breadNumber: 2 },
            component: () => import("@/views/pc/institution/regist/Step4.vue"),
          },
          {
            path: "/institution/regist/step5",
            name: "institution.menu.regist_step5",
            meta: { auth: true, breadNumber: 2 },
            component: () => import("@/views/pc/institution/regist/Step5.vue"),
          },
        ]
      },
      {
        path: "/institution/regist/pending/:id",
        name: "institution.menu.regist_pending",
        meta: { auth: false, breadNumber: 1 },
        component: () => import("@/views/pc/institution/regist/Pending.vue"),
      }      
    ]
  },
  {
    path: "/student/index",
    name: "studentIndex",
    component: () => import("@/views/pc/student/Index.vue"),
    redirect: "/student/dashboard",
    children: [{
        path: "/student/dashboard",
        name: "student.menu.dashboard",
        meta: { auth: true, breadNumber: 1, breadFirst: 'student.menu.my_learning' },
        component: () => import("@/views/pc/student/dashboard/Index.vue"),
      },
      {
        path: "/student/virtual",
        name: "student.menu.virtual_courses",
        meta: { auth: true, breadNumber: 1, breadFirst: 'student.menu.my_learning' },
        component: () => import("@/views/pc/student/virtual/Index.vue")
      },
      {
        path: "/student/virtual/details/:id",
        name: "student.menu.virtual_details",
        meta: { auth: true, breadNumber: 2 },
        component: () => import("@/views/pc/student/virtual/Details.vue"),
      },
      {
        path: "/student/live",
        name: "student.menu.live_courses",
        meta: { auth: true, breadNumber: 1, breadFirst: 'student.menu.my_learning' },
        component: () => import("@/views/pc/student/live/Index.vue")
      },
      {
        path: "/student/live/details/:id",
        name: "student.menu.live_details",
        meta: { auth: true, breadNumber: 2 },
        component: () => import("@/views/pc/student/live/Details.vue"),
      },
      {
        path: "/student/record",
        name: "student.menu.record_courses",
        meta: { auth: true, breadNumber: 1, breadFirst: 'student.menu.my_learning' },
        component: () => import("@/views/pc/student/record/Index.vue"),
      },
      {
        path: "/student/record/details/:id",
        name: "student.menu.record_details",
        meta: { auth: true, breadNumber: 2, breadFirst: 'student.menu.my_learning' },
        component: () => import("@/views/pc/student/record/Details.vue"),
      },
      {
        path: "/student/space",
        name: "student.menu.virtual_space",
        meta: { auth: true, breadNumber: 1, breadFirst: 'student.menu.plan' },
        component: () => import("@/views/pc/student/space/Index.vue"),
      },
      {
        path: "/student/order",
        name: "student.menu.order_list",
        meta: { auth: true, breadNumber: 1, breadFirst: 'student.menu.others' },
        component: () => import("@/views/pc/student/order/Index.vue"),
      },
      {
        path: "/student/order/details/:id",
        name: "student.menu.order_details",
        meta: { auth: true, breadNumber: 2 },
        component: () => import("@/views/pc/student/order/Details.vue"),
      },
      {
        path: "/student/order/payment/:id",
        name: "student.menu.order_payment",
        meta: { auth: true, breadNumber: 2 },
        component: () => import("@/views/pc/student/order/PayConfirm.vue"),
      },
      {
        path: "/student/account",
        name: "student.menu.my_account",
        component: () => import("@/views/pc/student/account/Index.vue"),
        redirect: "/student/personal",
        children: [{
          path: "/student/personal",
          name: "student.menu.personal_info",
          meta: { auth: true, breadNumber: 1, breadFirst: 'student.menu.others' },
          component: () => import("@/views/pc/student/account/personal/Index.vue"),
        },
        {
          path: "/student/security",
          name: "student.menu.sign_in_security",
          meta: { auth: true, breadNumber: 1, breadFirst: 'student.menu.others' },
          component: () => import("@/views/pc/student/account/security/Index.vue"),
        },
        {
          path: "/student/security/changePassword",
          name: "student.menu.change_password",
          meta: { auth: true, breadNumber: 2 },
          component: () => import("@/views/pc/student/account/security/ChangePassword.vue"),
        },
        {
          path: "/student/security/changeEmail",
          name: "student.menu.change_email",
          meta: { auth: true, breadNumber: 2 },
          component: () => import("@/views/pc/student/account/security/ChangeEmail.vue"),
        },
        {
          path: "/student/security/changePhone",
          name: "student.menu.change_phone",
          meta: { auth: true, breadNumber: 2 },
          component: () => import("@/views/pc/student/account/security/ChangePhone.vue"),
        },
        {
          path: "/student/identity",
          name: "student.menu.identity_verification",
          meta: { auth: true, breadNumber: 1, breadFirst: 'student.menu.others' },
          component: () => import("@/views/pc/student/account/identity/Index.vue"),
        },
        {
          path: "/student/viewHistory",
          name: "student.menu.view_history",
          meta: { auth: true, breadNumber: 1, breadFirst: 'student.menu.others' },
          component: () => import("@/views/pc/student/account/history/Index.vue"),
        },
        {
          path: "/student/wishlist",
          name: "student.menu.wishlist",
          meta: { auth: true, breadNumber: 1, breadFirst: 'student.menu.others' },
          component: () => import("@/views/pc/student/account/wishlist/Index.vue"),
        }]
      },
      {
        path: "/student/help",
        name: "student.menu.help_center",
        meta: { auth: true, breadNumber: 1, breadFirst: 'student.menu.others' },
        component: () => import("@/views/pc/student/center/Index.vue"),
      },
      {
        path: "/student/refund",
        name: "student.menu.refund_order",
        meta: { auth: true, breadNumber: 2, breadFirst: 'student.menu.others'},
        component: () => import("@/views/pc/student/center/refund/Index.vue"),
      },
      {
        path: "/student/refund/details/:id",
        name: "student.menu.refund_details",
        meta: { auth: true, breadNumber: 2, breadFirst: 'student.menu.others'},
        component: () => import("@/views/pc/student/center/refund/Details.vue"),
      },
      {
        path: "/student/review",
        name: "student.menu.review_feedback",
        meta: { auth: true, breadNumber: 2, breadFirst: 'student.menu.others'},
        component: () => import("@/views/pc/student/center/review/Index.vue"),
      },
      {
        path: "/student/question",
        name: "student.menu.question_reply",
        component: () => import("@/views/pc/student/center/question/Index.vue"),
        meta: { auth: true, breadNumber: 2, breadFirst: 'student.menu.others'},
      }
    ]
  },
  {
    path: "/institution/index",
    name: "InstitutionIndex",
    component: () => import("@/views/pc/institution/Index.vue"),
    redirect: "/institution/dashboard",
    children: [
      {
        path: "/institution/dashboard",
        name: "institution.menu.dashboard",
        meta: { auth: true, permission: true, breadNumber: 1, breadFirst: 'institution.menu.institution' },
        component: () => import("@/views/pc/institution/dashboard/Index.vue"),
      },
      {
        path: "/institution/income/mine",
        name: "institution.menu.incomes",
        meta: { auth: true, permission: true, breadNumber: 1, breadFirst: 'institution.menu.institution' },
        component: () => import("@/views/pc/institution/income/Index.vue"),
      },
      {
        path: "/institution/income/all",
        name: "institution.menu.income_of_institution",
        meta: { auth: true, permission: true, breadNumber: 1, breadFirst: 'institution.menu.institution' },
        component: () => import("@/views/pc/institution/income/All.vue"),
      },
      {
        path: "/institution/income/incomeHistory",
        name: "institution.menu.income_history",
        meta: { auth: true, permission: true, breadNumber: 2 },
        component: () => import("@/views/pc/institution/income/IncomeHistory.vue"),
      },
      {
        path: "/institution/income/withdrawHistory",
        name: "institution.menu.withdraw_history",
        meta: { auth: true, permission: true, breadNumber: 2 },
        component: () => import("@/views/pc/institution/income/withdrawHistory.vue"),
      },
      {
        path: "/institution/virtual",
        name: "institution.menu.virtual_classroome",
        meta: { auth: true, permission: true, breadNumber: 1, breadFirst: 'institution.menu.institution' },
        component: () => import("@/views/pc/institution/virtual/Index.vue"),
        redirect: "/institution/virtual/course",
        children: [{
          path: "/institution/virtual/course",
          name: "institution.menu.virtual_course",
          meta: { auth: true, permission: true, breadNumber: 1},
          component: () => import("@/views/pc/institution/virtual/course/Index.vue"),
        },
        {
          path: "/institution/virtual/usage",
          name: "institution.menu.virtual_usage",
          meta: { auth: true, permission: true, breadNumber: 1},
          component: () => import("@/views/pc/institution/virtual/usage/Index.vue"),
        }]
      },
      {
        path: "/institution/virtual/details/:id",
        name: "institution.menu.virtual_details",
        meta: { auth: true, permission: true, breadNumber: 2 },
        component: () => import("@/views/pc/institution/virtual/course/Details.vue"),
      },
      {
        path: "/institution/live",
        name: "institution.menu.start_live",
        meta: { auth: true, permission: true, breadNumber: 1, breadFirst: 'institution.menu.institution' },
        component: () => import("@/views/pc/institution/live/Index.vue")
      },
      {
        path: "/institution/live/details/:id",
        name: "institution.menu.live_details",
        meta: { auth: true, permission: true, breadNumber: 2 },
        component: () => import("@/views/pc/institution/live/Details.vue"),
      },
      {
        path: "/institution/course/:type",
        name: "institution.menu.courses",
        meta: { auth: true, permission: true, breadNumber: 1, breadFirst: 'institution.menu.institution' },
        component: () => import("@/views/pc/institution/course/Index.vue"),
      },
      {
        path: "/institution/course/details/:type",
        name: "institution.menu.course_details",
        meta: { auth: true, permission: true, breadNumber: 2 },
        component: () => import("@/views/pc/institution/course/details/Index.vue"),
        children: [{
          path: "/institution/course/details/contents/:type",
          name: "institution.menu.course_details_contents",
          meta: { auth: true, permission: true, breadNumber: 2},
          component: () => import("@/views/pc/institution/course/details/Contents.vue"),
        },
        {
          path: "/institution/course/details/anouncements/:type",
          name: "institution.menu.course_details_anouncements",
          meta: { auth: true, permission: true, breadNumber: 2},
          component: () => import("@/views/pc/institution/course/details/Anouncements.vue"),
        }]
      },
      {
        path: "/institution/course/edit/:type",
        name: "institution.menu.course_edit",
        meta: { auth: true, permission: true, breadNumber: 2 },
        component: () => import("@/views/pc/institution/course/edit/Index.vue"),
        children: [{
            path: "/institution/course/edit/step1/:type",
            name: "institution.menu.course_edit_step1",
            meta: { auth: true, permission: true, breadNumber: 2 },
            component: () => import("@/views/pc/institution/course/edit/Step1.vue"),
        },
        {
          path: "/institution/course/edit/step2/:type",
          name: "institution.menu.course_edit_step2",
          meta: { auth: true, permission: true, breadNumber: 2 },
          component: () => import("@/views/pc/institution/course/edit/Step2.vue"),
        },
        {
          path: "/institution/course/edit/step3/:type",
          name: "institution.menu.course_edit_step3",
          meta: { auth: true, permission: true, breadNumber: 2 },
          component: () => import("@/views/pc/institution/course/edit/Step3.vue"),
        },
        {
          path: "/institution/course/edit/step4/:type",
          name: "institution.menu.course_edit_step4",
          meta: { auth: true, permission: true, breadNumber: 2 },
          component: () => import("@/views/pc/institution/course/edit/Step4.vue"),
        },
        {
          path: "/institution/course/edit/step5/:type",
          name: "institution.menu.course_edit_step5",
          meta: { auth: true, permission: true, breadNumber: 2 },
          component: () => import("@/views/pc/institution/course/edit/Step5.vue"),
        }]
      },
      {
        path: "/institution/student/:type",
        name: "institution.menu.students",
        meta: { auth: true, permission: true, breadNumber: 1, breadFirst: 'institution.menu.institution' },
        component: () => import("@/views/pc/institution/student/Index.vue"),
      },
      {
        path: "/institution/resource",
        name: "institution.menu.resources",
        meta: { auth: true, permission: true, breadNumber: 1, breadFirst: 'institution.menu.institution' },
        component: () => import("@/views/pc/institution/resource/Index.vue"),
      },
      {
        path: "/institution/resource/details",
        name: "institution.menu.resource_details",
        meta: { auth: true, permission: true, breadNumber: 2 },
        component: () => import("@/views/pc/institution/resource/Details.vue"),
      },
      {
        path: "/institution/instructor/details",
        name: "institution.menu.instructor_details",
        meta: { auth: true, permission: true, breadNumber: 2 },
        component: () => import("@/views/pc/institution/instructor/Details.vue"),
      },
      {
        path: "/institution/order/:type",
        name: "institution.menu.orders",
        meta: { auth: true, breadNumber: 1, breadFirst: 'institution.menu.sales' },
        component: () => import("@/views/pc/institution/order/Index.vue"),
      },
      {
        path: "/institution/order/details/:type/:id",
        name: "institution.menu.order_details",
        meta: { auth: true, permission: true, breadNumber: 2 },
        component: () => import("@/views/pc/institution/order/Details.vue"),
      },
      {
        path: "/institution/voucher",
        name: "institution.menu.vouchers",
        meta: { auth: true, permission: true, breadNumber: 1, breadFirst: 'institution.menu.sales' },
        component: () => import("@/views/pc/institution/voucher/Index.vue"),
      },
      {
        path: "/institution/voucher/details",
        name: "institution.menu.voucher_details",
        meta: { auth: true, permission: true, breadNumber: 2 },
        component: () => import("@/views/pc/institution/voucher/Details.vue"),
      },
      {
        path: "/institution/voucher/view",
        name: "institution.menu.voucher_view",
        meta: { auth: true, permission: true, breadNumber: 2 },
        component: () => import("@/views/pc/institution/voucher/View.vue"),
      },
      {
        path: "/institution/service/:type",
        name: "institution.menu.service",
        meta: { auth: true, permission: true, breadNumber: 1, breadFirst: 'institution.menu.sales' },
        component: () => import("@/views/pc/institution/service/Index.vue"),
      },
      {
        path: "/institution/refund/:type",
        name: "institution.menu.refund_order",
        meta: { auth: true, permission: true, breadNumber: 2 },
        component: () => import("@/views/pc/institution/service/refund/Index.vue"),
      },
      {
        path: "/institution/refund/details/:type/:id",
        name: "institution.menu.refund_details",
        meta: { auth: true, permission: true, breadNumber: 2 },
        component: () => import("@/views/pc/institution/service/refund/Details.vue"),
      },
      {
        path: "/institution/review/:type",
        name: "institution.menu.review_feedback",
        meta: { auth: true, permission: true, breadNumber: 2 },
        component: () => import("@/views/pc/institution/service/review/Index.vue"),
      },
      {
        path: "/institution/question/:type",
        name: "institution.menu.question_reply",
        meta: { auth: true, permission: true, breadNumber: 2 },
        component: () => import("@/views/pc/institution/service/question/Index.vue"),
      },
      {
        path: "/institution/account",
        name: "institution.menu.account",
        meta: { auth: true, permission: true, breadNumber: 1, breadFirst: 'institution.menu.others' },
        component: () => import("@/views/pc/institution/account/Index.vue"),
        redirect: "/institution/account/baseinfo",
        children: [{
          path: "/institution/account/baseinfo",
          name: "institution.menu.account_baseinfo",
          meta: { auth: true, permission: true, breadNumber: 2},
          component: () => import("@/views/pc/institution/account/baseinfo/Index.vue"),
        },
        {
          path: "/institution/account/security",
          name: "institution.menu.account_security",
          meta: { auth: true, permission: true, breadNumber: 2},
          component: () => import("@/views/pc/institution/account/security/Index.vue"),
        },
        {
          path: "/institution/account/security/changePassword",
          name: "institution.menu.account_change_password",
          meta: { auth: true, permission: true, breadNumber: 3 },
          component: () => import("@/views/pc/institution/account/security/ChangePassword.vue"),
        },
        {
          path: "/institution/account/security/changeEmail",
          name: "institution.menu.account_change_email",
          meta: { auth: true, permission: true, breadNumber: 3 },
          component: () => import("@/views/pc/institution/account/security/ChangeEmail.vue"),
        },
        {
          path: "/institution/account/security/changePhone",
          name: "institution.menu.account_change_phone",
          meta: { auth: true, permission: true, breadNumber: 3 },
          component: () => import("@/views/pc/institution/account/security/ChangePhone.vue"),
        },
        {
          path: "/institution/account/identity",
          name: "institution.menu.account_identity",
          meta: { auth: true, permission: true, breadNumber: 2},
          component: () => import("@/views/pc/institution/account/identity/Index.vue"),
        },
        {
          path: "/institution/account/onstore",
          name: "institution.menu.account_onstore",
          meta: { auth: true, permission: true, breadNumber: 2},
          component: () => import("@/views/pc/institution/account/onstore/Index.vue"),
        },
      ]
      },
      {
        path: "/institution/instructor",
        name: "institution.menu.instructors",
        meta: { auth: true, permission: true, breadNumber: 1, breadFirst: 'institution.menu.others' },
        component: () => import("@/views/pc/institution/instructor/Index.vue"),
      },
      {
        path: "/institution/permission",
        name: "institution.menu.permission",
        meta: { auth: true, permission: true, breadNumber: 1, breadFirst: 'institution.menu.others' },
        component: () => import("@/views/pc/institution/permission/Index.vue"),
        redirect: "/institution/permission/account",
        children: [{
          path: "/institution/permission/account",
          name: "institution.menu.permission_account",
          meta: { auth: true, permission: true, breadNumber: 2},
          component: () => import("@/views/pc/institution/permission/account/Index.vue"),
        },
        {
          path: "/institution/permission/editaccount",
          name: "institution.menu.permission_account_edit",
          meta: { auth: true, permission: true, breadNumber: 3},
          component: () => import("@/views/pc/institution/permission/account/EditAccount.vue"),
        },
        {
          path: "/institution/permission/role",
          name: "institution.menu.permission_role",
          meta: { auth: true, permission: true, breadNumber: 2},
          component: () => import("@/views/pc/institution/permission/role/Index.vue"),
        },
        {
          path: "/institution/permission/editrole",
          name: "institution.menu.permission_role_edit",
          meta: { auth: true, permission: true, breadNumber: 3},
          component: () => import("@/views/pc/institution/permission/role/EditRole.vue"),
        },
        {
          path: "/institution/permission/roledetails",
          name: "institution.menu.permission_role_details",
          meta: { auth: true, permission: true, breadNumber: 3},
          component: () => import("@/views/pc/institution/permission/role/Details.vue"),
        }]
      },
      {
        path: "/institution/setting",
        name: "institution.menu.settings",
        meta: { auth: true, permission: true, breadNumber: 1, breadFirst: 'institution.menu.others' },
        component: () => import("@/views/pc/institution/setting/Index.vue"),
        redirect: "/institution/setting/baseinfo",
        children: [{
          path: "/institution/setting/baseinfo",
          name: "institution.menu.settings_baseinfo",
          meta: { auth: true, permission: true, breadNumber: 2},
          component: () => import("@/views/pc/institution/setting/baseinfo/Index.vue"),
        },
        {
          path: "/institution/setting/websetting",
          name: "institution.menu.settings_websetting",
          meta: { auth: true, permission: true, breadNumber: 2},
          component: () => import("@/views/pc/institution/setting/websetting/Index.vue"),
        },
        {
          path: "/institution/setting/course",
          name: "institution.menu.settings_course",
          meta: { auth: true, permission: true, breadNumber: 2},
          component: () => import("@/views/pc/institution/setting/course/Index.vue"),
        }]
      },
      {
        path: "/institution/systemlog",
        name: "institution.menu.system_log",
        meta: { auth: true, permission: true, breadNumber: 1, breadFirst: 'institution.menu.others' },
        component: () => import("@/views/pc/institution/systemlog/Index.vue"),
      }
    ]
  }  
];
export default pcRouter;
