
import request from "@/utils/request";
import fetch from "@/utils/fetch";

// 查询商品分类树状列表
export function findPrdGoodsClassTreeList(data) {
  return request({
    url: "/v1/prd/prdgoodsclass/findPrdGoodsClassTreeList",
    method: "post",
    data
  })
}

// 查询商品详情
export function findPrdGoods(data) {
  return request({
    url: "/v1/prd/prdgoods/findPrdGoods",
    method: "post",
    data
  })
}

// 查询商品标签
export function findPrdTagList(data) {
  return request({
    url: "/v1/prd/prdtag/findPrdTagList",
    method: "post",
    data
  })
}

// 获取商品列表
export function findPrdGoodsList(data) {
  return request({
    url: "/v1/prd/prdgoods/findPrdGoodsList",
    method: "post",
    data
  })
}

// 讲师获取商品列表
export function findPrdGoodsListStore(data) {
  return request({
    url: "/v1/prd/prdgoods/findPrdGoodsListStore",
    method: "post",
    data
  })
}

// 机构获取商品列表
export function findPrdGoodsListSeller(data) {
  return request({
    url: "/v1/prd/prdgoods/findPrdGoodsListSeller",
    method: "post",
    data
  })
}

// 保存商品
export function savePrdGoods(data) {
  return request({
    url: "/v1/prd/prdgoods/savePrdGoods",
    method: "post",
    data
  })
}

// 删除商品
export function deletePrdGoods(data) {
  return request({
    url: "/v1/prd/prdgoods/deletePrdGoods",
    method: "post",
    data
  })
}

// 更新商品
export function editPrdGoods(data) {
  return request({
    url: "/v1/prd/prdgoods/editPrdGoods",
    method: "post",
    data
  })
}

// 获取Section列表
export function findPrdSectionList(data) {
  return request({
    url: "/v1/prd/prdsection/findPrdSectionList",
    method: "post",
    data
  })
}

// 保存Section
export function savePrdSection(data) {
  return request({
    url: "/v1/prd/prdsection/savePrdSection",
    method: "post",
    data
  })
}

// 更新Section
export function editPrdSection(data) {
  return request({
    url: "/v1/prd/prdsection/editPrdSection",
    method: "post",
    data
  })
}

// 批量更新Section
export function editPrdSectionList(data) {
  return request({
    url: "/v1/prd/prdsection/editPrdSectionList",
    method: "post",
    data
  })
}

// 删除Section
export function deletePrdSection(data) {
  return request({
    url: "/v1/prd/prdsection/deletePrdSection",
    method: "post",
    data
  })
}

// 获取Lecture列表
export function findPrdLectureList(data) {
  return request({
    url: "/v1/prd/prdlecture/findPrdLectureList",
    method: "post",
    data
  })
}

// 保存Lecture
export function savePrdLecture(data) {
  return request({
    url: "/v1/prd/prdlecture/savePrdLecture",
    method: "post",
    data
  })
}

// 更新Lecture
export function editPrdLecture(data) {
  return request({
    url: "/v1/prd/prdlecture/editPrdLecture",
    method: "post",
    data
  })
}

// 批量更新Lecture
export function editPrdLectureList(data) {
  return request({
    url: "/v1/prd/prdlecture/editPrdLectureList",
    method: "post",
    data
  })
}


// 删除Lecture
export function deletePrdLecture(data) {
  return request({
    url: "/v1/prd/prdlecture/deletePrdLecture",
    method: "post",
    data
  })
}

// 添加Lecture资源表
export function savePrdLectureResource(data) {
  return request({
    url: "/v1/prd/prdlectureresource/savePrdLectureResource",
    method: "post",
    data
  })
}

// 批量添加Lecture资源表
export function savePrdLectureResourceList(data) {
  return request({
    url: "/v1/prd/prdlectureresource/savePrdLectureResourceList",
    method: "post",
    data
  })
}

// 删除Lecture资源
export function deletePrdLectureResource(data) {
  return request({
    url: "/v1/prd/prdlectureresource/deletePrdLectureResource",
    method: "post",
    data
  })
}

// 查询各分类的商品数量
export function findPrdGoodsClassChildrenList(data) {
  return request({
    url: "/v1/prd/prdgoodsclass/findPrdGoodsClassChildrenList",
    method: "post",
    data
  })
}

// 查询主页商品列表
export function findPrdGoodsListAll(data) {
  return request({
    url: "/v1/prd/prdgoods/findPrdGoodsListAll",
    method: "post",
    data
  })
}

// 查询主页商品详情
export function findPrdGoodsMember(data) {
  return request({
    url: "/v1/prd/prdgoods/findPrdGoodsMember",
    method: "post",
    data
  })
}

// 查询广告商品列表
export function findPrdAdvertList(data) {
  return request({
    url: "/v1/prd/prdadvertapi/findPrdAdvertList",
    method: "post",
    data
  })
}

// 获取购物车展示数据
export function findPrdGoodsListCart(data) {
  return request({
    url: "/v1/prd/prdgoods/findPrdGoodsListCart",
    method: "post",
    data
  })
}

// 添加购物车
export function saveMemCart(data) {
  return request({
    url: "/v1/prd/memcart/saveMemCart",
    method: "post",
    data
  })
}

// 删除购物车
export function deleteMemCart(data) {
  return request({
    url: "/v1/prd/memcart/deleteMemCart",
    method: "post",
    data
  })
}

// 获取购物车
export function findMemCartList(data) {
  return request({
    url: "/v1/prd/memcart/findMemCartList",
    method: "post",
    data
  })
}

// 更新购物车
export function editMemCart(data) {
  return request({
    url: "/v1/prd/memcart/editMemCart",
    method: "post",
    data
  })
}

// 更新购物车
export function findPrdGoodsListMember(data) {
  return request({
    url: "/v1/prd/prdgoods/findPrdGoodsListMember",
    method: "post",
    data
  })
}

// 获取收藏商品列表
export function findMemGoodsSaveList(data) {
  return request({
    url: "/v1/prd/memgoodssave/findMemGoodsSaveList",
    method: "post",
    data
  })
}
// 收藏商品
export function saveMemGoodsSaveList(data) {
  return request({
    url: "/v1/prd/memgoodssave/saveMemGoodsSaveList",
    method: "post",
    data
  })
}
// 取消收藏商品
export function deleteMemGoodsSave(data) {
  return request({
    url: "/v1/prd/memgoodssave/deleteLogicMemGoodsSave",
    method: "post",
    data
  })
}

// 保存足迹
export function saveMemViewHistoryList(data) {
  return request({
    url: "/v1/prd/memviewhistory/saveMemViewHistoryList",
    method: "post",
    data
  })
}

// 删除足迹
export function deleteMemViewHistory(data) {
  return request({
    url: "/v1/prd/memviewhistory/deleteMemViewHistory",
    method: "post",
    data
  })
}

// 清除会员所有足迹
export function clearMemViewHistory(data) {
  return request({
    url: "/v1/prd/memviewhistory/clearMemViewHistory",
    method: "post",
    data
  })
}

// 获取会员的浏览足迹列表
export function findMemViewHistoryListMember(data) {
  return request({
    url: "/v1/prd/memviewhistory/findMemViewHistoryListMember",
    method: "post",
    data
  })
}

// 获取会员的商品收藏列表
export function findMemGoodsSaveListMember(data) {
  return request({
    url: "/v1/prd/memgoodssave/findMemGoodsSaveListMember",
    method: "post",
    data
  })
}

// 获取会员的直播录播课程详情
export function findPrdGoodsMemberDetail(data) {
  return request({
    url: "/v1/prd/prdgoods/findPrdGoodsMemberDetail",
    method: "post",
    data
  })
}

// 获取浏览了讲师课程的学生列表
export function findMemViewHistoryListSeller(data) {
  return request({
    url: "/v1/prd/memviewhistory/findMemViewHistoryListSeller",
    method: "post",
    data
  })
}

// 获取收藏了讲师课程的学生列表
export function findMemGoodsSaveListSeller(data) {
  return request({
    url: "/v1/prd/memgoodssave/findMemGoodsSaveListSeller",
    method: "post",
    data
  })
}

// 获取浏览了机构课程的学生列表
export function findMemViewHistoryListStore(data) {
  return request({
    url: "/v1/prd/memviewhistory/findMemViewHistoryListStore",
    method: "post",
    data
  })
}

// 获取收藏了机构课程的学生列表
export function findMemGoodsSaveListStore(data) {
  return request({
    url: "/v1/prd/memgoodssave/findMemGoodsSaveListStore",
    method: "post",
    data
  })
}

// 获取课程中的工具列表
export function findPrdGoodsToolList(data) {
  return request({
    url: "/v1/prd/prdgoodstool/findPrdGoodsToolList",
    method: "post",
    data
  })
}

// 删除课程中的工具
export function deletePrdGoodsTool(data) {
  return request({
    url: "/v1/prd/prdgoodstool/deletePrdGoodsTool",
    method: "post",
    data
  })
}

// 添加课程的工具
export function savePrdGoodsTool(data) {
  return request({
    url: "/v1/prd/prdgoodstool/savePrdGoodsTool",
    method: "post",
    data
  })
}

// 保存商品通知
export function savePrdAnnounce(data) {
  return request({
    url: "/v1/prd/prdannounce/savePrdAnnounce",
    method: "post",
    data
  })
}

// 编辑商品通知
export function editPrdAnnounce(data) {
  return request({
    url: "/v1/prd/prdannounce/editPrdAnnounce",
    method: "post",
    data
  })
}

// 删除商品通知
export function deletePrdAnnounce(data) {
  return request({
    url: "/v1/prd/prdannounce/deletePrdAnnounce",
    method: "post",
    data
  })
}

// 查询商品通知列表
export function findPrdAnnounceList(data) {
  return request({
    url: "/v1/prd/prdannounce/findPrdAnnounceList",
    method: "post",
    data
  })
}

// 删除商品通知回应
export function deletePrdAnnounceResponse(data) {
  return request({
    url: "/v1/prd/prdannounceresponse/deletePrdAnnounceResponse",
    method: "post",
    data
  })
}

// 保存商品通知回应
export function savePrdAnnounceResponse(data) {
  return request({
    url: "/v1/prd/prdannounceresponse/savePrdAnnounceResponse",
    method: "post",
    data
  })
}

// 编辑商品通知回应
export function editPrdAnnounceResponse(data) {
  return request({
    url: "/v1/prd/prdannounceresponse/editPrdAnnounceResponse",
    method: "post",
    data
  })
}

// 保存问题
export function savePrdQuestion(data) {
  return request({
    url: "/v1/prd/prdquestion/savePrdQuestion",
    method: "post",
    data
  })
}

// 删除问题
export function deletePrdQuestion(data) {
  return request({
    url: "/v1/prd/prdquestion/deletePrdQuestion",
    method: "post",
    data
  })
}

// 查询问题
export function findPrdQuestionList(data) {
  return request({
    url: "/v1/prd/prdquestion/findPrdQuestionList",
    method: "post",
    data
  })
}

// 查询问题(讲师)
export function findPrdQuestionListSeller(data) {
  return request({
    url: "/v1/prd/prdquestion/findPrdQuestionListSeller",
    method: "post",
    data
  })
}

// 查询问题(机构)
export function findPrdQuestionListStore(data) {
  return request({
    url: "/v1/prd/prdquestion/findPrdQuestionListStore",
    method: "post",
    data
  })
}

// 添加回答
export function savePrdAnswer(data) {
  return request({
    url: "/v1/prd/prdanswer/savePrdAnswer",
    method: "post",
    data
  })
}
// 删除回答
export function deletePrdAnswer(data) {
  return request({
    url: "/v1/prd/prdanswer/deletePrdAnswer",
    method: "post",
    data
  })
}
// 更新回答
export function editPrdAnswer(data) {
  return request({
    url: "/v1/prd/prdanswer/editPrdAnswer",
    method: "post",
    data
  })
}
// 问题点赞
export function savePrdQuestionThumbsup(data) {
  return request({
    url: "/v1/prd/prdquestionthumbsup/savePrdQuestionThumbsup",
    method: "post",
    data
  })
}

// 取消问题点赞
export function cancelOrdReviewThumbsup(data) {
  return request({
    url: "/v1/prd/prdquestionthumbsup/cancelOrdReviewThumbsup",
    method: "post",
    data
  })
}

// 获取各种类的问题数量
export function countPrdQuestionClass(data) {
  return request({
    url: "/v1/prd/prdquestion/countPrdQuestionClass",
    method: "post",
    data
  })
}

// 获取近一年内客户浏览数统计
export function countMemViewHistory(data) {
  return request({
    url: "/v1/prd/memviewhistory/countMemViewHistory",
    method: "post",
    data
  })
}

// 近一年内收藏课程的客户统计
export function countMemGoodsSave(data) {
  return request({
    url: "/v1/prd/memgoodssave/countMemGoodsSave",
    method: "post",
    data
  })
}

// 统计商品一分类数量
export function countClassGoods(data) {
  return request({
    url: "/v1/prd/prdgoods/countClassGoods",
    method: "post",
    data
  })
}

// 统计课程类型数量
export function countTypeGoods(data) {
  return request({
    url: "/v1/prd/prdgoods/countTypeGoods",
    method: "post",
    data
  })
}

// 发起私聊
export function saveMemChat(data) {
  return request({
    url: "/v1/prd/memchat/saveMemChat",
    method: "post",
    data
  })
}

// 查看私聊列表
export function findMemChatList(data) {
  return request({
    url: "/v1/prd/memchat/findMemChatList",
    method: "post",
    data
  })
}

// 获取私聊内容
export function findMemChat(data) {
  return request({
    url: "/v1/prd/memchat/findMemChat",
    method: "post",
    data
  })
}

// 回复私聊
export function saveMemChatDetail(data) {
  return request({
    url: "/v1/prd/memchatdetail/saveMemChatDetail",
    method: "post",
    data
  })
}

// 发送VR教室链接
export function sendVRLinkUrl(data) {
  return request({
    url: "/v1/prd/prdlecture/sendVRLinkUrl",
    method: "post",
    data
  })
}

export function saveCloudSubscribe(data) {
  return request({
    url: "/v1/prd/cloudsubscribe/saveCloudSubscribe",
    method: "post",
    data
  })
}

export function findCloudSubscribeListMember(data) {
  return request({
    url: "/v1/prd/cloudsubscribe/findCloudSubscribeListMember",
    method: "post",
    data
  })
}

export function saveCloudCustomize(data) {
  return request({
    url: "/v1/prd/cloudcustomize/saveCloudCustomize",
    method: "post",
    data
  })
}

export function findCloudRoomListMember(data) {
  return request({
    url: "/v1/prd/cloudroom/findCloudRoomListMember",
    method: "post",
    data
  })
}

export function findCloudPlanMember(data) {
  return request({
    url: "/v1/prd/cloudplan/findCloudPlanMember",
    method: "post",
    data
  })
}

