import { createI18n } from "vue-i18n/dist/vue-i18n.esm-bundler.js";
import store from "@/store";

const i18n = createI18n({
  locale: store.state.user.locale || "en", //默认显示的语言
  messages: {
    en: require("./locales/en.json"),
    zh: require("./locales/zh.json"), //引入语言文件
    tc: require("./locales/tc.json"),
  },
});

export default i18n;
