import request from "@/utils/request";

// 邮箱地址注册会员
export function regMemberByEmail(data) {
  return request({
    url: "/v1/mem/memmember/regMemberByEmail",
    method: "post",
    data
  })
}

// 手机注册会员
export function regMemberByMobile(data) {
  return request({
    url: "/v1/mem/memmember/regMemberByMobile",
    method: "post",
    data
  })
}

// 发送注册邮件验证码
export function sendRegisterEmailCode(data) {
  return request({
    url: "/v1/mem/memmember/sendRegisterEmailCode",
    method: "post",
    data
  })
}

// 发送注册短信验证码
export function sendRegisterSmsCode(data) {
  return request({
    url: "/v1/mem/memmember/sendRegisterSmsCode",
    method: "post",
    data
  })
}

// 发送忘记密码邮件
export function sendForgetEmailCode(data) {
  return request({
    url: "/v1/mem/memmember/sendForgetEmailCode",
    method: "post",
    data
  })
}

// 发送忘记密码短信
export function sendForgetPasswordSms(data) {
  return request({
    url: "/v1/mem/memmember/sendForgetPasswordSms",
    method: "post",
    data
  })
}

// 忘记密码，邮件
export function forgetPasswordEmail(data) {
  return request({
    url: "/v1/mem/memmember/forgetPasswordEmail",
    method: "post",
    data
  })
}

// 忘记密码，短信
export function forgetPassword(data) {
  return request({
    url: "/v1/mem/memmember/forgetPassword",
    method: "post",
    data
  })
}

// 获取会员信息
export function getMemberInfo(data) {
  return request({
    url: "/v1/mem/memmember/getMemberInfo",
    method: "post",
    data
  })
}

// 机构登录
export function instructorLogin(data) {
  return request({
    url: "/v1/sel/memmember/login",
    method: "post",
    data
  })
}

// 会员登陆
export function memberLogin(data) {
  return request({
    url: "/v1/mem/memmember/login",
    method: "post",
    data
  })
}

// 获取会员所在的机构列表
export function getMemberStores(data) {
  return request({
    url: "/v1/sel/memmember/getMemberStores",
    method: "post",
    data
  })
}

// 进入机构
export function enterStore(data) {
  return request({
    url: "/v1/sel/stostore/getStore",
    method: "post",
    data
  })
}

// 获取入驻申请的数据
export function findNotOpenStoStore(data) {
  return request({
    url: "/v1/sel/stostore/findNotOpenStoStore",
    method: "post",
    data
  })
}

// 修改密码
export function modifyPassword(data) {
  return request({
    url: "/v1/mem/memmember/modifyPassword",
    method: "post",
    data
  })
}

// 获取系统参数
export function findSysSettingList(data) {
  return request({
    url: '/v1/adm/syssetting/findSysSettingList',
    method: 'post',
    data
  })
}

export function saveSysContact(data) {
  return request({
    url: "/v1/adm/syscontact/saveSysContact",
    method: "post",
    data
  })
}
