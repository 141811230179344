const state = {
  navPathList: [],
};

const mutations = {
  NAV_PATH_LIST_ADD(state,obj){
    state.navPathList.push(obj);
  },
  NAV_PATH_LIST_REMOVE(state,num){
    if (num == 0) {
      state.navPathList = [];
    } else {
      if(state.navPathList.length > 1){
        state.navPathList = state.navPathList.slice(0, num);
      }
    }
  },
};

const actions = {
  addPathList({ commit }, data) {
    commit('NAV_PATH_LIST_ADD', data)
  },
  removePathList({ commit }, data) {
    commit('NAV_PATH_LIST_REMOVE', data)
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
