import store from "@/store";

export function setBreadcrumb(route) {
  // console.log('route', route);
  if (!route.meta || typeof route.meta.breadNumber === undefined) {
    store.dispatch("nav/removePathList", 0);
    return;
  }

  var breadNumber = route.meta.breadNumber; 
  var newBread = { name: route.name, path: route.fullPath, query: route.query };
  if (breadNumber == 0) {
    store.dispatch("nav/removePathList", 0);
    store.dispatch("nav/addPathList", newBread);
    return;
  }

  if (breadNumber == 1 && route.meta.breadFirst) {
    store.dispatch("nav/removePathList", 0);
    store.dispatch("nav/addPathList", { name: route.meta.breadFirst });
    store.dispatch("nav/addPathList", newBread);
    return;
  }

  let breadLength = store.state.nav.navPathList.length;
  let prevRoute = route.redirectedFrom;
  if (prevRoute === undefined) {
    if (breadLength >= breadNumber) {
      store.dispatch("nav/removePathList", parseInt(breadNumber));
      store.dispatch("nav/addPathList", newBread);
    } else {
      store.dispatch("nav/addPathList", newBread);
    }
  } else if (breadLength === breadNumber) {
    store.dispatch("nav/removePathList", parseInt(breadNumber));
    store.dispatch("nav/addPathList", newBread);
  } else {
    var prevBread = { name: prevRoute.name, path: prevRoute.fullPath, query: prevRoute.query };
    if (parseInt(breadNumber) < 3) {
      store.dispatch("nav/removePathList", 0);
      store.dispatch("nav/addPathList", { name: prevRoute.meta.breadFirst });
      store.dispatch("nav/addPathList", prevBread);
      store.dispatch("nav/addPathList", newBread);
    } else {
      store.dispatch("nav/removePathList", parseInt(breadNumber) - 1);
      store.dispatch("nav/addPathList", prevBread);
      store.dispatch("nav/addPathList", newBread);
    }
  }
}
