const state = {
  institutionIndex: '1',
  studentIndex: '1',
  showMobileMenu: false
};

const mutations = {
  INSTITUTION_INDEX_SET(state, index){
    state.institutionIndex = index;
  },
  INSTITUTION_INDEX_RESET(state, obj){
    state.institutionIndex = '1';
  },
  STUDENT_INDEX_SET(state, index){
    state.studentIndex = index;
  },
  STUDENT_INDEX_RESET(state, obj){
    state.studentIndex = '1';
  },
  SHOW_MOBILE_MENU(state, obj){
    state.showMobileMenu = obj;
  }
};

const actions = {
  setInstitutionIndex({ commit }, data) {
    commit('INSTITUTION_INDEX_SET', data)
  },
  resetInstitutionIndex({ commit }, data) {
    commit('INSTITUTION_INDEX_RESET', data)
  },
  setStudentIndex({ commit }, data) {
    commit('STUDENT_INDEX_SET', data)
  },
  resetStudentIndex({ commit }, data) {
    commit('STUDENT_INDEX_RESET', data)
  },
  showMobileMenu({ commit }) {
    commit('SHOW_MOBILE_MENU', true)
  },
  hideMobileMenu({ commit }) {
    commit('SHOW_MOBILE_MENU', false)
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
