import { saveMemGoodsSaveList, deleteMemGoodsSave, findMemGoodsSaveListMember } from "@/api/product";
import { saveMemStoreSaveList, deleteMemStoreSave, findMemStoreSaveListMember } from "@/api/seller";
import { getToken } from "@/utils/auth";

const state = {
  goodsList: [],
  storeList: [],
};

const mutations = {
  SET_GOODS_LIST: (state, locale) => {
    state.goodsList = locale;
  },
  SET_STORE_LIST: (state, locale) => {
    state.storeList = locale;
  },
};

const actions = {
  // 初始化收藏
  initGoods({ commit }, params) {
    return new Promise((resolve) => {
      findMemGoodsSaveListMember({page: {paging: false}}).then(res => {
        if (res.success) {
          let savelist = [];
          res.data.forEach(item => {
            savelist.push({
              id: item.id,
              goodsId: item.goodsId
            })
          })
          commit("SET_GOODS_LIST", savelist);
          resolve();
        }
      })
      resolve();
    });
  },

  initStore({ commit }, params) {
    return new Promise((resolve) => {
      findMemStoreSaveListMember({page: {paging: false}}).then(res => {
        if (res.success) {
          let savelist = [];
          res.data.forEach(item => {
            savelist.push({
              id: item.id,
              storeId: item.storeId
            })
          })
          commit("SET_STORE_LIST", savelist);
          resolve();
        }
      })
    });
  },

  // 保存收藏到后台
  saveGoods({ commit, state }, params) {
    return new Promise((resolve) => {
      if (state.goodsList.length === 0) {
        findMemGoodsSaveListMember({page: {paging: false}}).then(res => {
          if (res.success) {
            let savelist = [];
            res.data.forEach(item => {
              savelist.push({
                id: item.id,
                goodsId: item.goodsId
              })
            })
            commit("SET_GOODS_LIST", savelist);
            resolve();
          }
        })
      } else {
        let goodsIds = [];
        state.goodsList.forEach(item => {
          goodsIds.push(item.goodsId);
        })
        saveMemGoodsSaveList({goodsIds}).then(res => {
          if (res.success) {
            findMemGoodsSaveListMember({page: {paging: false}}).then(res => {
              if (res.success) {
                let savelist = [];
                res.data.forEach(item => {
                  savelist.push({
                    id: item.id,
                    goodsId: item.goodsId
                  })
                })
                commit("SET_GOODS_LIST", savelist);
                resolve();
              }
            })
          }
        })
      }
    });
  },

  saveStore({ commit, state }, params) {
    return new Promise((resolve) => {
      if (state.storeList.length === 0) {
        findMemStoreSaveListMember({page: {paging: false}}).then(res => {
          if (res.success) {
            let savelist = [];
            res.data.forEach(item => {
              savelist.push({
                id: item.id,
                storeId: item.storeId
              })
            })
            commit("SET_STORE_LIST", savelist);
            resolve();
          }
        })
      } else {
        let storeIds = [];
        state.storeList.forEach(item => {
          storeIds.push(item.storeId);
        })
        saveMemStoreSaveList({storeIds}).then(res => {
          if (res.success) {
            findMemStoreSaveListMember({page: {paging: false}}).then(res => {
              if (res.success) {
                let savelist = [];
                res.data.forEach(item => {
                  savelist.push({
                    id: item.id,
                    storeId: item.storeId
                  })
                })
                commit("SET_STORE_LIST", savelist);
                resolve();
              }
            })
          }
        })
      }
    });
  },

  // 添加收藏
  addGoods({ commit, state }, params) {
    return new Promise((resolve) => {
      if (getToken('ISTUDY_TOKEN')) {
        saveMemGoodsSaveList({goodsIds: [params]}).then(res => {
          if (res.success) {
            findMemGoodsSaveListMember({page: {paging: false}}).then(res => {
              if (res.success) {
                let savelist = [];
                res.data.forEach(item => {
                  savelist.push({
                    id: item.id,
                    goodsId: item.goodsId
                  })
                })
                commit("SET_GOODS_LIST", savelist);
                resolve();
              }
            })
          }
        })
      } else {
        let goodsList = [...state.goodsList, {goodsId: params}];
        commit("SET_GOODS_LIST", goodsList);
        resolve();
      }
    });
  },
  addStore({ commit, state }, params) {
    return new Promise((resolve) => {
      if (getToken('ISTUDY_TOKEN')) {
        saveMemStoreSaveList({storeIds: [params]}).then(res => {
          if (res.success) {
            findMemStoreSaveListMember({page: {paging: false}}).then(res => {
              if (res.success) {
                let savelist = [];
                res.data.forEach(item => {
                  savelist.push({
                    id: item.id,
                    storeId: item.storeId
                  })
                })
                commit("SET_STORE_LIST", savelist);
                resolve();
              }
            })
          }
        })
      } else {
        let storeList = [...state.storeList, {storeId: params}];
        commit("SET_STORE_LIST", storeList);
        resolve();
      }
    });
  },

  // 删除收藏
  removeGoods({ commit, state }, params) {
    return new Promise((resolve) => {
      if (getToken('ISTUDY_TOKEN')) {
        deleteMemGoodsSave({ids: [params.id]}).then(res => {
          if (res.success) {
            findMemGoodsSaveListMember({page: {paging: false}}).then(res => {
              if (res.success) {
                let savelist = [];
                res.data.forEach(item => {
                  savelist.push({
                    id: item.id,
                    goodsId: item.goodsId
                  })
                })
                commit("SET_GOODS_LIST", savelist);
                resolve();
              }
            })
          }
        })
      } else {
        commit("SET_GOODS_LIST", state.goodsList.filter(item => item.goodsId != params.goodsId));
        resolve();
      }
    });
  },

  removeStore({ commit, state }, params) {
    return new Promise((resolve) => {
      if (getToken('ISTUDY_TOKEN')) {
        deleteMemStoreSave({ids: [params.id]}).then(res => {
          if (res.success) {
            findMemStoreSaveListMember({page: {paging: false}}).then(res => {
              if (res.success) {
                let savelist = [];
                res.data.forEach(item => {
                  savelist.push({
                    id: item.id,
                    storeId: item.storeId
                  })
                })
                commit("SET_STORE_LIST", savelist);
                resolve();
              }
            })
          }
        })
      } else {
        commit("SET_STORE_LIST", state.storeList.filter(item => item.storeId != params.storeId));
        resolve();
      }
    });
  },

  // 清空收藏
  clearGoods({ commit, state }, params) {
    return new Promise((resolve) => {
      if (getToken('ISTUDY_TOKEN')) {
        deleteMemGoodsSave({ids: params}).then(res => {
          if (res.success) {
            commit("SET_GOODS_LIST", []);
            resolve();
          }
        })
      } else {
        commit("SET_GOODS_LIST", []);
        resolve();
      }
    });
  },

  clearStore({ commit, state }, params) {
    return new Promise((resolve) => {
      if (getToken('ISTUDY_TOKEN')) {
        deleteMemStoreSave({ids: params}).then(res => {
          if (res.success) {
            commit("SET_STORE_LIST", []);
            resolve();
          }
        })
      } else {
        commit("SET_STORE_LIST", []);
        resolve();
      }
    });
  },  
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
