import request from "@/utils/request";

// 查询会员
export function searchMemMemberList(data) {
  return request({
    url: "/v1/sel/memmember/searchMemMemberList",
    method: "post",
    data
  })
}

// 发送邀请
export function saveStoSellerInvite(data) {
  return request({
    url: "/v1/sel/stosellerinvite/saveStoSellerInvite",
    method: "post",
    data
  })
}

// 取消邀请
export function deleteStoSellerInvite(data) {
  return request({
    url: "/v1/sel/stosellerinvite/deleteStoSellerInvite",
    method: "post",
    data
  })
}

// 再次发送邀请
export function resendStoSellerInvite(data) {
  return request({
    url: "/v1/sel/stosellerinvite/resendStoSellerInvite",
    method: "post",
    data
  })
}

// 获取会员详情
export function findMemMember(data) {
  return request({
    url: "/v1/sel/memmember/findMemMember",
    method: "post",
    data
  })
}

// 保存会员详情
export function editMemMember(data) {
  return request({
    url: "/v1/sel/memmember/editMemMember",
    method: "post",
    data
  })
}

// 获取讲师目录列表 
export function findSelFolderList(data) {
  return request({
    url: "/v1/sel/selfolder/findSelFolderList",
    method: "post",
    data
  })
}

// 添加讲师目录
export function saveSelFolder(data) {
  return request({
    url: "/v1/sel/selfolder/saveSelFolder",
    method: "post",
    data
  })
}

// 编辑讲师目录
export function editSelFolder(data) {
  return request({
    url: "/v1/sel/selfolder/editSelFolder",
    method: "post",
    data
  })
}

// 删除讲师目录
export function deleteSelFolder(data) {
  return request({
    url: "/v1/sel/selfolder/deleteSelFolder",
    method: "post",
    data
  })
}

// 获取讲师目录的资源列表
export function findSelFolder(data) {
  return request({
    url: "/v1/sel/selfolder/findSelFolder",
    method: "post",
    data
  })
}

// 获取Homepage用讲师详情
export function findSelSellerMember(data) {
  return request({
    url: "/v1/sel/selseller/findSelSellerMember",
    method: "post",
    data
  })
}

// 获取Homepage用讲师列表
export function findStoSellerListMember(data) {
  return request({
    url: "/v1/sel/stoseller/findStoSellerListMember",
    method: "post",
    data
  })
}

// 收藏机构
export function saveMemStoreSaveList(data) {
  return request({
    url: "/v1/sel/memstoresave/saveMemStoreSaveList",
    method: "post",
    data
  })
}
// 取消收藏机构
export function deleteMemStoreSave(data) {
  return request({
    url: "/v1/sel/memstoresave/deleteLogicMemStoreSave",
    method: "post",
    data
  })
}

// 获取会员的收藏机构列表
export function findMemStoreSaveListMember(data) {
  return request({
    url: "/v1/sel/memstoresave/findMemStoreSaveListMember",
    method: "post",
    data
  })
}

// 获取收藏机构列表 
export function findMemStoreSaveListSeller(data) {
  return request({
    url: "/v1/sel/memstoresave/findMemStoreSaveListSeller",
    method: "post",
    data
  })
}

// 获取系统通知 
export function findMemNotificationList(data) {
  return request({
    url: "/v1/sel/memnotification/findMemNotificationList",
    method: "post",
    data
  })
}

// 已读系统通知 
export function readMemNotification(data) {
  return request({
    url: "/v1/sel/memnotification/readMemNotification",
    method: "post",
    data
  })
}

// 机构提现申请
export function applyStoWithdrawal(data) {
  return request({
    url: "/v1/sel/stowithdrawal/applyStoWithdrawal",
    method: "post",
    data
  })
}

// 获取提现数据
export function getWithdrawalStatistic(data) {
  return request({
    url: "/v1/sel/stowithdrawal/getWithdrawalStatistic",
    method: "post",
    data
  })
}

// 获取提现列表数据
export function findStoWithdrawalList(data) {
  return request({
    url: "/v1/sel/stowithdrawal/findStoWithdrawalList",
    method: "post",
    data
  })
}

// 邀请讲师
export function acceptSellerInviteLink(data) {
  return request({
    url: "v1/sel/stosellerinvite/acceptSellerInviteLink",
    method: "post",
    data
  })
}

// 邀请讲师
export function findStoSellerVrusageList(data) {
  return request({
    url: "v1/sel/stosellervrusage/findStoSellerVrusageList",
    method: "post",
    data
  })
}

export function countNotCloseVrusage(data) {
  return request({
    url: "v1/sel/stosellervrusage/countNotCloseVrusage",
    method: "post",
    data
  })
}
