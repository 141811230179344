const state = {
  courseStep: 1,
};

const mutations = {
  COURSE_NEXT_STEP(state){
    state.courseStep = state.courseStep + 1;
  },
  COURSE_PREV_STEP(state){
    if (state.courseStep > 0) {
      state.courseStep = state.courseStep - 1;
    }
  },
  COURSE_RESET_STEP(state){
    state.courseStep = 1;
  },
  COURSE_SET(state, step){
    state.courseStep = step;
  },
};

const actions = {
  courseNext({ commit }) {
    commit('COURSE_NEXT_STEP')
  },
  coursePrev({ commit }) {
    commit('COURSE_PREV_STEP')
  },
  courseReset({ commit }) {
    commit('COURSE_RESET_STEP')
  },
  courseSet({ commit }, params) {
    commit('COURSE_SET', params)
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
