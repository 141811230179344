import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueI18n from "./language";
import store from "./store";
import vClickout from "vue3-clickout";
import Element from "element-plus";
import "element-plus/dist/index.css";
import "@/svgIcon/index.js";
import svgIcon from "@/svgIcon/index.vue";
import "@/assets/fonts/font.scss"; // 引入字体样式
import "animate.css/animate.min.css";
import "@/config/permission";
import Vue3Autocounter from "vue3-autocounter";
import Vue3StarRatings from "vue3-star-ratings";
import VueBoringAvatars from "vue-boring-avatars";
import Vue3Avatar from "vue3-avatar";
import Vue3VideoPlay from 'vue3-video-play'; // 引入组件
import 'vue3-video-play/dist/style.css'; // 引入css
import Vue3VideoPlayer from '@cloudgeek/vue3-video-player';
import '@cloudgeek/vue3-video-player/dist/vue3-video-player.css';
import { quillEditor } from "vue3-quill";
import 'default-passive-events';

const app = createApp(App)
  .use(store)
  .use(router)
  .use(VueI18n)
  .use(vClickout)
  .use(Element, {})
  .use(Vue3VideoPlayer)
  .component("svgIcon", svgIcon)
  .component("vue3-autocounter", Vue3Autocounter)
  .component("vue3-star-ratings", Vue3StarRatings)
  .component("vue-boring-avatars", VueBoringAvatars)
  .component("vue3-avatar", Vue3Avatar)
  .component("vue3-video-play", Vue3VideoPlay)
  .component("vue3-quill", quillEditor);
 
// 定义全局函数
app.config.globalProperties.toBr = (value) => {
  if (value) {
    return value.replaceAll('\n', '<br>');
  } else {
    return '';
  }
};

store.dispatch("user/setSettings");
store.dispatch("user/loadStripe");

router.isReady().then(() => {
  app.mount('#app');
});