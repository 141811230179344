import { findPrdGoodsListCart, findMemCartList, saveMemCart, deleteMemCart, editMemCart } from "@/api/product";
import { getToken } from "@/utils/auth";

const state = {
  cartInfo: [],
  coupon: [],
};

const mutations = {
  SET_CART_INFO: (state, locale) => {
    state.cartInfo = locale;
  },
};

const actions = {
  // 初始化购物车
  init({ commit }, params) {
    return new Promise((resolve) => {
      findMemCartList({}).then(res => {
        if (res.success) {
          commit("SET_CART_INFO", res.data);
          resolve();
        }
      })
    });
  },

  // 保存购物车到后台
  save({ commit, state }, params) {
    return new Promise((resolve) => {
      if (state.cartInfo.length === 0) {
        findMemCartList({}).then(res => {
          if (res.success) {
            commit("SET_CART_INFO", res.data);
            resolve();
          }
        })
      } else {
        let goodsIds = [];
        state.cartInfo.forEach(item => {
          goodsIds.push(item.goodsId);
        })
        saveMemCart({goodsIds}).then(res => {
          if (res.success) {
            findMemCartList({}).then(res => {
              if (res.success) {
                commit("SET_CART_INFO", res.data);
                resolve();
              }
            })
          }
        })
      }
    });
  },

  // 添加购物车
  add({ commit, state }, params) {
    return new Promise((resolve) => {
      if (getToken('ISTUDY_TOKEN')) {
        saveMemCart({goodsIds: params}).then(res => {
          if (res.success) {
            findMemCartList({}).then(res => {
              if (res.success) {
                commit("SET_CART_INFO", res.data);
                resolve();
              }
            })
          }
        })
      } else {
        let arr = [];
        params.forEach(id => {
          var cart = state.cartInfo.filter(item => item.goodsId === id);
          if (!cart || cart.length === 0) arr.push(id);
        })
  
        if (arr.length === 0) {
          resolve();
        } else {
          findPrdGoodsListCart({ids: arr}).then(res => {
            if (res.success) {
              var cartInfo = res.data.map(item => {
                item.goodsId = item.id;
                item.id = null;
                return item;
              });
              cartInfo = [...state.cartInfo, ...cartInfo];
              commit("SET_CART_INFO", cartInfo);
              resolve();
            }
          })
        }
      }
    });
  },

  // 删除购物车
  remove({ commit, state }, params) {
    return new Promise((resolve) => {
      if (getToken('ISTUDY_TOKEN')) {
        deleteMemCart({ids: [params.id]}).then(res => {
          if (res.success) {
            findMemCartList({}).then(res => {
              if (res.success) {
                commit("SET_CART_INFO", res.data);
                resolve();
              }
            })
          }
        })
      } else {
        commit("SET_CART_INFO", state.cartInfo.filter(item => item.goodsId != params.goodsId));
        resolve();
      }
    });
  },

  // 更新购物车
  change({ commit, state }, params) {
    return new Promise((resolve) => {
      if (getToken('ISTUDY_TOKEN')) {
        editMemCart(params).then(res => {
          if (res.success) {
            findMemCartList({}).then(res => {
              if (res.success) {
                commit("SET_CART_INFO", res.data);
                resolve();
              }
            })
          }
        })
      } else {
        var cartInfo = [...state.cartInfo];
        cartInfo.forEach(item => {
          if (item.goodsId === params.goodsId) {
            item.leaveMessage = params.leaveMessage;
          }
        })
        commit("SET_CART_INFO", cartInfo);
        resolve();
      }
    });
  },

  // 清空购物车
  clear({ commit, state }, params) {
    return new Promise((resolve) => {
      if (getToken('ISTUDY_TOKEN')) {
        var ids = state.cartInfo.map(item => {
          return item.id;
        })

        deleteMemCart({ids}).then(res => {
          if (res.success) {
            commit("SET_CART_INFO", []);
            resolve();
          }
        })
      } else {
        commit("SET_CART_INFO", []);
        resolve();
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
