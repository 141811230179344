import router from '@/router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken } from '@/utils/auth'
import { setBreadcrumb } from "@/utils/breadcrumb";
import store from "@/store";

NProgress.configure({ showSpinner: false })

const whiteList = ['/login', '/regist', '/forgetPassword', '/setPassword', '/401', '/404']

router.beforeEach(async(to, from, next) => {
  if (to.meta && to.meta.permission) {
    if (!store.getters.roleFunction(to.path)) {
      next({ path: '/401' })
    }
  }

  setBreadcrumb(to);
  if (whiteList.indexOf(to.path) !== -1) {
    // in the free login whitelist, go directly
    next()
  } else if (!to.meta || !to.meta.auth) {
    next()
  } else {
    NProgress.start()
    // 设置页面title
    const ISTUDY_TOKEN = getToken('ISTUDY_TOKEN')
    if (ISTUDY_TOKEN) {
      if (to.path === '/login') {
        // 如果已登录，请重定向到主页
        next({ path: '/index' })
      } else {
        next()
      }
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next("/login")
    }
    NProgress.done()
  }
})

router.afterEach(() => {
  NProgress.done()
})
