const state = {
  tabName: {
    login: '0',
    institutionVirtual: '1',
    institutionLiveCourse: '1',
    institutionMyCourse: '1',
    institutionCourse: '1',
    institutionStudent: '1',
    institutionResource: ['1'],
    institutionResourcePicture: '1',
    institutionResourceVideo: '1',
    institutionResourceDocument: '1',
    institutionAccount: '1',
    institutionPermission: '1',
    institutionSetting: '1',
    institutionVoucher: '1',
    studentVirtualCourse: '1',
    studentLiveCourse: '1',
    studentRecordCourse: '1',
    studentAccount: '1',
    studentSpace: '1',
  },
  searchQuery: {
    globalSearch: {},
    homepageSearch: { page: { index: 1, size: 10, }, keys: {} },
    institutionVirtual: { page: { index: 1, size: 10, }, keys: {} },
    institutionLiveCourse: { page: { index: 1, size: 10, }, keys: { progress: 0 } },
    institutionInstructor: { page: { index: 1, size: 10, }, keys: {} },
    institutionMyCourse: { page: { index: 1, size: 10, }, keys: {} },
    institutionCourse: { page: { index: 1, size: 10, }, keys: {} },
    institutionResource: { keys: { pictureKeys: {}, videoKeys: {}, documentKeys: {} }},
    institutionMyOrder: { page: { index: 1, size: 10, }, keys: {} },
    institutionOrder: { page: { index: 1, size: 10, }, keys: {} },
    institutionVoucher: { page: { index: 1, size: 10, }, keys: {} },
    institutionAccount: { page: { index: 1, size: 10, }, keys: {} },
    institutionMyQuestion: { page: { index: 1, size: 10, }, keys: {} },
    institutionQuestion: { page: { index: 1, size: 10, }, keys: {} },
    institutionMyReview: { page: { index: 1, size: 10, }, keys: {} },
    institutionReview: { page: { index: 1, size: 10, }, keys: {} },
    institutionMyRefund: { page: { index: 1, size: 10, }, keys: {} },
    institutionRefund: { page: { index: 1, size: 10, }, keys: {} },
    studentVoucher: { page: { index: 1, size: 10, }, keys: {} },
    studentOrder: { page: { index: 1, size: 10, }, keys: {} },
    studentRefund: { page: { index: 1, size: 10, }, keys: {} },
    studentVirtualCourse: { page: { index: 1, size: 10, }, keys: { progress: 0 } },
    studentLiveCourse: { page: { index: 1, size: 10, }, keys: { progress: 0 } },
    studentRecordCourse: { page: { index: 1, size: 10, }, keys: {} },
    studentReview: { page: { index: 1, size: 10, }, keys: {} },
    studentQuestion: { page: { index: 1, size: 10, }, keys: {} },
    studentSpace: { page: { index: 1, size: 10, }, keys: {} },
  }
};

const mutations = {
  SET_TAB_NAME: (state, data) => {
    state.tabName = { ...state.tabName, ...data}
  },
  SET_SEARCH_QUERY: (state, data) => {
    state.searchQuery = { ...state.searchQuery, ...data }
  },
};

const actions = {
  setTabName({ commit }, data) {
    commit('SET_TAB_NAME', data)
  },
  setSearchQuery({ commit }, data) {
    commit('SET_SEARCH_QUERY', data)
  },
  initInstitution({ commit }, data) {
    return new Promise((resolve) => {
      commit('SET_TAB_NAME', {
        institutionVirtual: '1',
        institutionLiveCourse: '1',
        institutionMyCourse: '1',
        institutionCourse: '1',
        institutionStudent: '1',
        institutionResource: ['1'],
        institutionResourcePicture: '1',
        institutionResourceVideo: '1',
        institutionResourceDocument: '1',
        institutionAccount: '1',
        institutionPermission: '1',
        institutionSetting: '1',
        institutionVoucher: '1',
      });
      commit('SET_SEARCH_QUERY', {
        institutionVirtual: { page: { index: 1, size: 10, }, keys: {} },
        institutionLiveCourse: { page: { index: 1, size: 10, }, keys: { progress: 0 } },
        institutionInstructor: { page: { index: 1, size: 10, }, keys: {} },
        institutionMyCourse: { page: { index: 1, size: 10, }, keys: {} },
        institutionCourse: { page: { index: 1, size: 10, }, keys: {} },
        institutionAccount: { page: { index: 1, size: 10, }, keys: {} },
        institutionResource: { keys: { pictureKeys: {}, videoKeys: {}, documentKeys: {} }},
        institutionMyOrder: { page: { index: 1, size: 10, }, keys: {} },
        institutionOrder: { page: { index: 1, size: 10, }, keys: {} },
        institutionVoucher: { page: { index: 1, size: 10, }, keys: {} },
        institutionMyQuestion: { page: { index: 1, size: 10, }, keys: {} },
        institutionQuestion: { page: { index: 1, size: 10, }, keys: {} },
        institutionMyReview: { page: { index: 1, size: 10, }, keys: {} },
        institutionReview: { page: { index: 1, size: 10, }, keys: {} },
        institutionMyRefund: { page: { index: 1, size: 10, }, keys: {} },
        institutionRefund: { page: { index: 1, size: 10, }, keys: {} },
          });
      resolve();
    });
  },
  initStudent({ commit }, data) {
    return new Promise((resolve) => {
      commit('SET_TAB_NAME', {
        studentVirtualCourse: '1',
        studentLiveCourse: '1',
        studentRecordCourse: '1',
        studentAccount: '1',
        studentSpace: '1',
      });
      commit('SET_SEARCH_QUERY', {
        studentVoucher: { page: { index: 1, size: 10, }, keys: {} },
        studentOrder: { page: { index: 1, size: 10, }, keys: {} },
        studentRefund: { page: { index: 1, size: 10, }, keys: {} },
        studentVirtualCourse: { page: { index: 1, size: 10, }, keys: { progress: 0 } },
        studentLiveCourse: { page: { index: 1, size: 10, }, keys: { progress: 0 } },
        studentRecordCourse: { page: { index: 1, size: 10, }, keys: {} },
        studentReview: { page: { index: 1, size: 10, }, keys: {} },
        studentQuestion: { page: { index: 1, size: 10, }, keys: {} },
        studentSpace: { page: { index: 1, size: 10, }, keys: {} },
      });
      resolve();
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
