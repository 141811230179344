export function isPC() {
  let userAgentInfo = navigator.userAgent;
  // console.log(userAgentInfo, 'userAgentInfo')
  let Agents = [
    "Android",
    "iPhone",
    "SymbianOS",
    "Windows Phone",
    "iPad",
    "iPod",
  ];
  let flag = true;
  for (let v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) >= 0) {
      flag = false;
      break;
    }
  }
  return flag;
}
